* {
  margin: 0;
  padding: 0;
  list-style: none;
  /* font-family: "Source Sans 3", sans-serif !important; */
  font-family: 'myriad-pro', 'Geneva', 'Tahoma', 'sans-serif' !important;
}

html,
body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

/* 设置滚动条的宽度 */
body::-webkit-scrollbar {
  width: 3px; /* 宽度 */
}

/* 滚动条轨道 */
body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 背景颜色 */
}

/* 滚动条-thumb */
body::-webkit-scrollbar-thumb {
  background-color: #888; /* 滑块颜色 */
  border-radius: 5px; /* 边框圆角 */
}

/* 悬停在滚动条上时的样式 */
body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
